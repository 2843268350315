import { PrimaryTable } from 'components/Table';
import { ColumnType, TableElement } from 'components/Table/types';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { TransactionType } from 'types/transaction';
import { fromWei } from 'web3-utils';
import BeatLoader from 'react-spinners/BeatLoader';
import { DataResponse } from '../../types/account';
import { Layout } from '../Layout';
import {
    AddressContainer,
    AddressInput,
    CentredContainer,
    InfoBox,
    InputContainer,
    LoaderBox,
    SendButton,
} from './styles';
import { Text } from '../common/Text';
import { Br } from '../common/Br';
import { EXPLORER_SITE_URL, WAT_VALUE } from '../../constants/env';

interface TransactionItemType extends TransactionType, TableElement {}

const txColumns: ColumnType<TransactionType>[] = [
    {
        title: 'Last deposits',
        dataKey: 'hash',
        getLink: (item) => `${EXPLORER_SITE_URL}/tx/${item.hash}`,
    },
    {
        title: 'To',
        dataKey: 'to',
        getLink: (item) => `${EXPLORER_SITE_URL}/address/${item.to}`,
    },
    {
        title: 'Value',
        dataKey: (data) => `${fromWei(data.value.toString(), 'ether')} WATER`,
    },
    {
        title: 'Timestamp',
        dataKey: 'date',
        align: 'center',
    },
];

interface IProps {
    data: DataResponse;
    onDeposit?: (address: string) => void;
    txData?: { message?: string; blockHash?: string } | null;
    isLoading: boolean;
    refetch: () => void;
}

export const MainPage: React.FC<IProps> = ({ data, onDeposit, txData, isLoading, refetch }) => {
    const [addressValue, setAddress] = useState<string>('');

    const clearInput = () => {
        setAddress('');
    };
    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        setAddress(e.target.value);
    };
    const handleOnSubmit = () => {
        onDeposit?.(addressValue);
    };

    useEffect(() => {
        if (!txData?.blockHash) return;
        clearInput();
        refetch();
    }, [txData?.blockHash]);

    return (
        <>
            <Layout>
                <CentredContainer>
                    <InfoBox>
                        <Text color="white" weight="medium" size={18}>
                            You get {WAT_VALUE} WATER
                        </Text>
                    </InfoBox>
                </CentredContainer>
                <Br indent={12} />
                <CentredContainer>
                    <AddressContainer>
                        <InputContainer>
                            <LoaderBox>
                                <BeatLoader color="#233848" size={7} loading={isLoading} />
                            </LoaderBox>
                            <AddressInput
                                onChange={handleOnChange}
                                value={addressValue}
                                isLoading={isLoading}
                                disabled={isLoading}
                                placeholder="Enter your address"
                            />
                        </InputContainer>
                        <SendButton onClick={handleOnSubmit} disabled={!addressValue || isLoading}>
                            <Text size={16}>Send Me</Text>
                        </SendButton>
                    </AddressContainer>
                </CentredContainer>
                <Br indent={8} />
                <CentredContainer>
                    <InfoBox>
                        <Text color="red" weight="medium">
                            {txData?.message || ''}
                        </Text>
                    </InfoBox>
                </CentredContainer>
                <Br indent={48} />
                <CentredContainer>
                    <Text size={32}>{fromWei(data.balance.toString(), 'ether')} WATER</Text>
                </CentredContainer>
                <Br indent={48} />
                {data.lastTransactions?.length ? (
                    <PrimaryTable<TransactionItemType>
                        layoutFixed
                        columns={txColumns}
                        items={data.lastTransactions.slice(0, 10)}
                    />
                ) : null}
            </Layout>
        </>
    );
};
