import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { MainPageContainer } from 'containers/MainPageContainer';
import { routes } from './constants/routes';

export const Router = () => {
    return (
        <Routes>
            <Route path={routes.main} element={<MainPageContainer />} />
            <Route path={routes.any} element={<Navigate to="/" />} />
        </Routes>
    );
};
