import React from 'react';
import { Layout } from 'components/Layout';
import PacmanLoader from 'react-spinners/PacmanLoader';

export const Loader = () => {
    return (
        <Layout>
            <PacmanLoader loading color="yellow" speedMultiplier={2} />
        </Layout>
    );
};
