import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { alignType } from './types';

export const Wrapper = styled.div`
    border-radius: 5px;
    width: 100%;
    max-width: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
        display: none;
    }
`;

export const TableContainer = styled.table<{ layoutFixed?: boolean }>`
    white-space: nowrap;
    border-collapse: collapse;
    min-width: 100%;
    max-width: 100%;

    ${({ layoutFixed }) => layoutFixed && 'table-layout: fixed;'}
`;

export const Title = styled.th<{ align?: alignType }>`
    text-align: ${({ align }) => align || 'left'};
    padding: 0 16px;
`;

export const Body = styled.tbody`
    background-color: #2d4557;
`;

export const Head = styled.thead`
    height: 56px;
    background-color: #273f52;
`;

export const Row = styled.tr`
    height: 52px;
    padding: 0 16px;
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.21);
`;

export const Column = styled.td<{ align?: alignType }>`
    text-align: ${({ align }) => align || 'left'};
    padding: 0 16px;
    position: relative;
    color: rgba(255, 255, 255, 0.87);
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 350px;

    @media (max-width: 768px) {
        max-width: 250px;
    }
    @media (max-width: 450px) {
        max-width: 170px;
    }
    @media (max-width: 320px) {
        max-width: 150px;
    }
`;

export const TableLink = styled(Link)`
    text-decoration: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

export const TableExternalLink = styled.a`
    text-decoration: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;
