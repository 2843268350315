import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Router } from './Routes';

const client = new QueryClient();

export const App: React.FC = () => {
    return (
        <>
            <QueryClientProvider client={client}>
                <BrowserRouter>
                    <Router />
                </BrowserRouter>
            </QueryClientProvider>
        </>
    );
};
