import { Link } from 'components/common/Link';
import styled from 'styled-components';

export const Container = styled.div`
    max-width: 1795px;
    height: 100%;
    display: flex;
    margin: 0 auto;
    padding: 0 60px;
    position: relative;

    @media (max-width: 1200px) {
        padding: 0 20px;
    }

    @media (max-width: 560px) {
        padding: 0;
        justify-content: center;
    }
`;

export const Wrapper = styled.header`
    width: 100%;
    height: 70px;
    background-color: #102636;
    margin-bottom: 60px;
`;

export const Filling = styled.div`
    max-width: 1155px;
`;

export const ExternalLink = styled.a`
    display: flex;
    align-items: center;
    margin-right: 16px;
    text-decoration: none;

    &:hover {
        & span {
            text-decoration: underline;
        }
    }
`;

export const HeaderLink = styled(Link)`
    display: flex;
    align-items: center;
    margin-right: 16px;

    &:hover {
        & span {
            text-decoration: underline;
        }
    }

    @media (max-width: 560px) {
        :first-child {
            margin-right: 16px;
        }
        :last-child {
            margin-right: 0;
        }
    }
`;

export const Navbar = styled.div`
    display: flex;
    align-items: center;
    width: 100%;

    @media (max-width: 650px) {
        justify-content: center;
    }
`;
