import styled from 'styled-components';
import { Text } from 'components/common/Text';

export const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    background-color: #233848;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Title = styled(Text).attrs({
    color: 'blue',
    size: 24,
})`
    display: inline-block;
    margin-bottom: 40px;
    @media (max-width: 425px) {
        margin-bottom: 20px;
    }
`;

export const Content = styled.main`
    margin-bottom: 100px;
    max-width: 100%;
`;
