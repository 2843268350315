import { MainPage } from 'components/MainPage';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Loader } from 'components/Loader';
import { useDeposit, useGetData } from '../hooks';
import { CAPTCHA_PUBLIC_KEY } from '../constants/env';

export const MainPageContainer: React.FC = () => {
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const { data, refetch } = useGetData();
    const { deposit, data: txData, isLoading } = useDeposit(recaptchaRef);

    return (
        <>
            <ReCAPTCHA ref={recaptchaRef} sitekey={CAPTCHA_PUBLIC_KEY} size="invisible" />
            {data?.balance !== undefined ? (
                <MainPage data={data} onDeposit={deposit} txData={txData} isLoading={isLoading} refetch={refetch} />
            ) : (
                <Loader />
            )}
        </>
    );
};
