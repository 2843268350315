import { useMutation, useQuery } from 'react-query';
import ReCAPTCHA from 'react-google-recaptcha';
import { Ref } from 'react';
import { DataResponse } from '../types/account';
import { API_URL } from '../constants/env';

export const useGetData = () => {
    const { data, ...otherData } = useQuery<DataResponse>('data', () => fetch(API_URL).then((res) => res.json()));

    return { data, ...otherData };
};

export const useDeposit = (recaptchaRef: Ref<ReCAPTCHA>) => {
    const { mutate: sendAddress, ...otherData } = useMutation<
        { message?: string; blockHash?: string },
        { message: string },
        { to: string; captchaToken: string }
    >(({ to, captchaToken }) =>
        fetch(`${API_URL}/sendTx`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ to, captchaToken }),
        }).then((res) => res.json())
    );

    const deposit = async (address: string) => {
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const recaptcha = recaptchaRef?.current as ReCAPTCHA;

            const captchaToken = await recaptcha?.executeAsync();
            if (!captchaToken) throw new Error('No ReCaptcha');
            await recaptcha?.reset();

            sendAddress({ to: address, captchaToken });
        } catch (e) {
            console.log(e);
        }
    };
    return { deposit, ...otherData };
};
