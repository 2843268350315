import styled from 'styled-components';

export const CentredContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const SendButton = styled.button`
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    width: 100px;
    height: 30px;
    background-color: #192f40;
    border: none;
    cursor: pointer;
`;

export const AddressInput = styled.input<{ isLoading: boolean }>`
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border: none;
    background-color: rgba(255, 255, 255, 0.87);
    width: min(600px, calc(100vw - 150px));
    height: 100%;
    outline: none;
    padding: 0 15px;
    font-family: Roboto;
    color: ${({ isLoading }) => (isLoading ? 'rgba(0, 0, 0, 0.3);' : '#000')};
`;

export const InputContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
`;

export const AddressContainer = styled.div`
    display: flex;
    max-width: 100%;
`;

export const InfoBox = styled.div`
    width: min(730px, calc(100vw - 20px));
`;

export const LoaderBox = styled.div`
    position: absolute;
    right: 5px;
`;
