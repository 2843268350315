import React from 'react';
import { Text } from 'components/common/Text';
import { Body, Column, Head, Row, TableContainer, TableExternalLink, TableLink, Title, Wrapper } from './styles';
import { ColumnType, TableElement, TableProps } from './types';

export const PrimaryTable = <T extends TableElement>({ columns, items, layoutFixed }: TableProps<T>) => {
    // eslint-disable-next-line react/no-unstable-nested-components
    const LinkedColumn: React.FC<{ to?: string } & Partial<ColumnType<T>>> = ({ to, children, ...props }) =>
        // eslint-disable-next-line no-nested-ternary
        !to ? (
            <Column>{children}</Column>
        ) : to.startsWith('http') ? (
            <Column {...props}>
                <TableExternalLink href={to} target="_blank" />
                {children}
            </Column>
        ) : (
            <Column {...props}>
                <TableLink to={to} />
                {children}
            </Column>
        );

    return (
        <Wrapper>
            <TableContainer layoutFixed={layoutFixed}>
                <Head>
                    <Row>
                        {columns.map((el) => (
                            <Title key={el.title} {...el}>
                                <Text size={18}>{el.title}</Text>
                            </Title>
                        ))}
                    </Row>
                </Head>
                <Body>
                    {items.map((item, idx) => (
                        <Row key={idx}>
                            {columns.map((el) => (
                                <LinkedColumn to={item.linkTo || el.getLink?.(item)} key={el.title} {...el}>
                                    <Text>
                                        {typeof el.dataKey === 'function' ? el.dataKey(item) : item[el.dataKey]}
                                    </Text>
                                </LinkedColumn>
                            ))}
                        </Row>
                    ))}
                </Body>
            </TableContainer>
        </Wrapper>
    );
};
