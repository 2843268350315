import { Header } from 'components/Header';
import React from 'react';
import { Container, Content, Title } from './styles';

interface Props {
    title?: string;
}

export const Layout: React.FC<Props> = ({ children, title }) => (
    <Container>
        <Header />
        <Content>
            {title && <Title>{title}</Title>}
            {children}
        </Content>
    </Container>
);
