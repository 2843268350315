import React from 'react';
import { Container, Filling, HeaderLink, Navbar, Wrapper } from './styles';
import { Logo } from '../Logo';

export const Header: React.FC = ({ children }) => (
    <Wrapper>
        <Container>
            <Navbar>
                <HeaderLink to="/">
                    <Logo />
                    <Filling>{children}</Filling>
                </HeaderLink>
            </Navbar>
        </Container>
    </Wrapper>
);
