declare global {
    interface Window {
        _env_: {
            REACT_APP_SITE_URL: string | undefined;
            REACT_APP_EXPLORER_SITE_URL: string | undefined;
            REACT_APP_CAPTCHA_PUBLIC_KEY: string | undefined;
            REACT_APP_API_URL: string | undefined;
            REACT_APP_VALUE: string | undefined;
        };
    }
}

export const EXPLORER_SITE_URL =
    (typeof window !== 'undefined' && window._env_
        ? window._env_.REACT_APP_EXPLORER_SITE_URL
        : process.env.REACT_APP_EXPLORER_SITE_URL) || 'https://explorer.waterfall.network';

export const SITE_URL =
    (typeof window !== 'undefined' && window._env_
        ? window._env_.REACT_APP_SITE_URL
        : process.env.REACT_APP_SITE_URL) || 'https://faucet-wf.dev.moonion.com';

export const API_URL =
    (typeof window !== 'undefined' && window._env_ ? window._env_.REACT_APP_API_URL : process.env.REACT_APP_API_URL) ||
    'https://faucet-wf.dev.moonion.com/api';

export const CAPTCHA_PUBLIC_KEY =
    (typeof window !== 'undefined' && window._env_
        ? window._env_.REACT_APP_CAPTCHA_PUBLIC_KEY
        : process.env.REACT_APP_CAPTCHA_PUBLIC_KEY) || '6Lc2IawdAAAAADtVoscZhpifYX1w4tpwMLGEmdmP';

export const WAT_VALUE =
    (typeof window !== 'undefined' && window._env_ ? window._env_.REACT_APP_VALUE : process.env.REACT_APP_VALUE) || '1';
